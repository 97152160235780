<template>
  <div class="home">
    <div class="header_top space_between">
      <handleback></handleback>
      <div>个人明细</div>
      <div>玩法说明</div>
    </div>
    <div class="tab space_around">
      <div
        @click.stop="handletype(1);playAudio()"
        class="btn center"
        :class="type == 1 ? 'active' : ''"
      >
        <div class="ic">提货账单</div>
      </div>
      <div
        @click.stop="handletype(2);playAudio()"
        class="btn center"
        :class="type == 2 ? 'active' : ''"
      >
        <div class="ic">收支明细</div>
      </div>
      <div
        @click.stop="handletype(3);playAudio()"
        class="btn center"
        :class="type == 3 ? 'active' : ''"
      >
        <div class="ic">充值明细</div>
      </div>
      <div
        @click.stop="handletype(4);playAudio()"
        class="btn center"
        :class="type == 4 ? 'active' : ''"
      >
        <div class="ic">分解明细</div>
      </div>
      <div class="slider" :style="{ left: left + 'px' }" ref="slider"></div>
    </div>
    <div class="main">
      <div v-if="type == 1">
        <div class="main_i_top grade4 center">
          <div
            class="btn center"
            @click="handlecategory(1)"
            :class="category == 1 ? 'active' : ''"
          >
            提取完成
          </div>
          <!-- <div
            class="btn center"
            @click="handlecategory(2)"
            :class="category == 2 ? 'active' : ''"
          >
            已分解
          </div> -->
          <div
            class="btn center"
            @click="handlecategory(3)"
            :class="category == 3 ? 'active' : ''"
          >
            冻结中
          </div>
          <div
            class="btn center"
            @click="handlecategory(4)"
            :class="category == 4 ? 'active' : ''"
          >
            申请提货
          </div>
          <!-- <div
            class="btn center"
            @click="handlecategory(5)"
            :class="category == 5 ? 'active' : ''"
          >
            正在发货
          </div> -->
          <div
            class="btn center"
            @click="handlecategory(6)"
            :class="category == 6 ? 'active' : ''"
          >
            等待收货
          </div>
        </div>

        <div class="deliveryRecords_box" @scroll="deliveryRecordsScroll">
          <div
            class="grade3"
            v-if="deliveryRecordsList.length > 0"
            style="margin-top: 13px"
          >
            <div
              class="main_i_weapon columns"
              :style="{ 'background-image': `url(${item.ornamentLevelImg})` }"
              v-for="(item, index) in deliveryRecordsList"
              :key="index"
            >
              <div class="mian_i_img">
                <img :src="item.ornamentImg" alt width="100%" />
              </div>

              <div class="textover-f">{{ item.ornamentName }}</div>
              <div class="money-money">
                <money class="money"></money>
                {{ item.ornamentsPrice }}
              </div>
            </div>
          </div>
          <div></div>
          <div v-if="category != 2">{{ deliveryRecordsIsOver }}</div>
        </div>
      </div>
      <div class="type space_around" v-if="type == 2">
        <div
          @click="handleRevenueListType('1');playAudio()"
          class="type_btn center"
          :class="RevenueListPage.moneyType == '1' ? 'active' : ''"
        >
          O币
        </div>
        <div
          @click="handleRevenueListType('2');playAudio()"
          class="type_btn center"
          :class="RevenueListPage.moneyType == '2' ? 'active' : ''"
        >
        G币
        </div>
      </div>
      <div class="revenueList" v-if="type == 2" @scroll="revenueListScroll">
        <div class="main_ii_top space_around">
          <div class="je">金额</div>
          <div class="yt">用途</div>
          <div class="zt">状态</div>
          <div class="sj">时间</div>
        </div>
        <div
          class="main_ii_center space_around"
          v-for="(item, index) in RevenueList"
          :key="index"
        >
          <div class="je">{{ item.amount }}</div>
          <div class="yt">{{ item.remark }}</div>
          <div class="zt">
            <div v-if="item.amount < 0" style="color: red">消费</div>
            <div v-else style="color: green">收入</div>

            <!-- <div v-else>其他</div> -->
          </div>
          <div class="sj">{{ item.createTime }}</div>
        </div>
        <!-- <div>{{ revenueListIsOver }}</div> -->
      </div>
      <div class v-if="type == 3">
        <div class="main_iii_top space_around">
          <div class="zfje">支付金额</div>
          <div class="zfqd">支付渠道</div>
          <div class="zfdh">支付单号</div>
          <div class="zfsj">支付时间</div>
        </div>
        <div class="recharge_box" @scroll="rechargeScroll">
          <div
          class="main_iii_center space_around"
          v-for="(item, index) in Recharge"
          :key="index"
        >
          <div class="zfje">{{ item.goodsPrice }}</div>
          <div class="zfqd">
            <div v-if="item.type == '2'">卡密</div>
            <div v-if="item.type == '3'">支付宝</div>
            <div v-if="item.type == '1'">支付宝</div>
            <div v-if="item.type == '4'">微信</div>
            <div v-if="item.type == '5'">微信</div>
            <div v-if="item.type == '6'">支付宝</div>
          </div>
          <div class="zfdh">{{ item.orderId }}</div>
          <div class="zfsj">{{ item.createTime }}</div>
        </div>
        </div>
      </div>
      <div class="" v-if="type == 4">
        <div
          class="grade3 fnjie_box"
          v-if="pickingList.length > 0"
          style="margin-top: 13px"
          @scroll="userdecompseListScroll"
        >
          <div
            class="main_i_weapon columns"
            :style="{ 'background-image': `url(${item.ornamentLevelImg})` }"
            v-for="(item, index) in pickingList"
            :key="index"
          >
            <div class="mian_i_img">
              <img :src="item.ornamentImgUrl" alt width="100%" />
            </div>

            <div class="textover-f">{{ item.ornamentName }}</div>
            <div class="money-money">
              <money class="money"></money>
              {{ item.ornamentsPrice }}
            </div>
          </div>
          <div></div>
          <!-- <div class="isOver">暂无更多</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserLogList,
  userdecompseList,
  deliveryRecords,
  rechargeRecords,
} from "@/api/index";
export default {
  data() {
    return {
      // key: value
      left: 0,
      category: 1,
      type: 1,
      detiallist: [],
      RevenueList: [],
      Recharge: [
        {
          count: 588,
          wwwL: "微信",
          status: "202310100000012135",
          time: "2023-10-10-00:00:00",
        },
        {
          count: 388,
          wwwL: "支付宝",
          status: "202310100000012135",
          time: "2023-10-10-00:00:00",
        },
        {
          count: 50000,
          wwwL: "支付宝",
          status: "202310100000012135",
          time: "2023-10-10-00:00:00",
        },
        {
          count: 888,
          wwwL: "卡密",
          status: "202310100000012135",
          time: "2023-10-10-00:00:00",
        },
        {
          count: 588,
          wwwL: "卡密",
          status: "202310100000012135",
          time: "2023-10-10-00:00:00",
        },
      ],
      pickingList: [],
      deliveryRecordsList: [],
      data: {
        success: true,
        data: {
          total: 264,
          pages: 7,
          page: 1,
          limit: 40,
        },
        errorCode: 0,
        errorMsg: null,
        errorData: null,
        errorCodeStr: null,
      },
      RevenueListPage: {
        moneyType: 1,
        page: 1,
        size: 10,
      },
      revenueListIsOver: "加载中",
      fenJieIsOver: "加载中",
      deliveryRecordsIsOver: "加载中",
      deliveryRecordsPage: {
        page: 1,
        size: 9,
        statusList: [10],
      },
      rechargeRecordsPage: {
        page: 1,
        size: 10,
      },
      userdecompseListPage: {
        page: 1,
        size: 12,
      },
      userdecompseListIsover: "加载中",
      rechargeRecordsIsover:'加载中'
    };
  },
  mounted() {
    setTimeout(() => {
      this.add();
    }, 1000);
    this.getlisttihuo();
  },
  methods: {
    playAudio(){
      this.$store.commit("playAudio");
    },
    rechargeScroll(e){
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.userdecompseListIsover == "加载中") {
          this.rechargeRecordsPage.page++;
          setTimeout(() => {
            this.getrechangeList();
          }, 5);
        }
      }
    },
    userdecompseListScroll(e) {
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.userdecompseListIsover == "加载中") {
          this.userdecompseListPage.page++;
          setTimeout(() => {
            this.getuserfenjie();
          }, 5);
        }
      }
    },
    deliveryRecordsScroll(e) {
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.deliveryRecordsIsOver == "加载中") {
          this.deliveryRecordsPage.page++;
          setTimeout(() => {
            this.getlisttihuo();
          }, 5);
        }
      }
    },
    revenueListScroll(e) {
      // console.log(e);
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.revenueListIsOver == "加载中") {
          this.RevenueListPage.page++;
          setTimeout(() => {
            this.getlisr();
          }, 5);
        }
      }
    },
    handleRevenueListType(res) {
      this.RevenueListPage.moneyType = res;
      this.RevenueListPage.page = 1;
      this.RevenueList = [];
      this.getlisr();
    },
    add() {
      this.data.data.list = [
        {
          id: "23547",
          appId: 730,
          itemId: "23547",
          itemName: "AWP | 狩猎网格 (久经沙场)",
          marketHashName: "AWP | Safari Mesh (Field-Tested)",
          shortName: "AWP | 狩猎网格",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgU2FmYXJpIE1lc2ggKEZpZWxkLVRlc3RlZCk=.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_uncommon_weapon",
          rarityName: "工业级",
          rarityColor: "#5E98D9",
          exterior: "wearcategory2",
          exteriorName: "久经沙场",
          exteriorColor: "#f0ad4e",
          sellType: 1,
          quantity: 819,
          currencyId: 0,
          price: "0.05",
          subsidyPrice: "0.26",
          sellerPrice: "0.26",
          cnyPrice: "0.26",

          recommendTagInfo: [
            {
              id: null,
              recommendTag: "热度过万",
              stairName: null,
              secondLevelName: null,
              detailTag: "热度过万",
              tagColour: "#ff0000",
              detailUrl: "c5game://main.app/popularSkin",
              recommendWeight: null,
              createTime: null,
              updateTime: null,
              attrKey: null,
              valueKey: null,
              status: null,
              recommendType: null,
              attrValueId: null,
              urlType: 1,
            },
          ],
          subsidyTag: null,
        },
        {
          id: "24507",
          appId: 730,
          itemId: "24507",
          itemName: "AWP | 狩猎网格 (破损不堪)",
          marketHashName: "AWP | Safari Mesh (Well-Worn)",
          shortName: "AWP | 狩猎网格",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgU2FmYXJpIE1lc2ggKFdlbGwtV29ybik=.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_uncommon_weapon",
          rarityName: "工业级",
          rarityColor: "#5E98D9",
          exterior: "wearcategory3",
          exteriorName: "破损不堪",
          exteriorColor: "#d9534f",
          sellType: 1,
          quantity: 53,
          currencyId: 0,
          price: "0.08",
          subsidyPrice: "0.45",
          sellerPrice: "0.45",
          cnyPrice: "0.45",

          recommendTagInfo: [],
          subsidyTag: null,
        },
        {
          id: "22424",
          appId: 730,
          itemId: "22424",
          itemName: "AWP | 狩猎网格 (略有磨损)",
          marketHashName: "AWP | Safari Mesh (Minimal Wear)",
          shortName: "AWP | 狩猎网格",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgU2FmYXJpIE1lc2ggKE1pbmltYWwgV2Vhcik=.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_uncommon_weapon",
          rarityName: "工业级",
          rarityColor: "#5E98D9",
          exterior: "wearcategory1",
          exteriorName: "略有磨损",
          exteriorColor: "#5cb85c",
          sellType: 1,
          quantity: 417,
          currencyId: 0,
          price: "0.09",
          subsidyPrice: "0.57",
          sellerPrice: "0.57",
          cnyPrice: "0.57",

          subsidyTag: null,
        },
        {
          id: "22353",
          appId: 730,
          itemId: "22353",
          itemName: "AWP | 狩猎网格 (战痕累累)",
          marketHashName: "AWP | Safari Mesh (Battle-Scarred)",
          shortName: "AWP | 狩猎网格",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgU2FmYXJpIE1lc2ggKEJhdHRsZS1TY2FycmVkKQ==.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_uncommon_weapon",
          rarityName: "工业级",
          rarityColor: "#5E98D9",
          exterior: "wearcategory4",
          exteriorName: "战痕累累",
          exteriorColor: "#B65C59",
          sellType: 1,
          quantity: 535,
          currencyId: 0,
          price: "0.11",
          subsidyPrice: "0.69",
          sellerPrice: "0.69",
          cnyPrice: "0.69",

          subsidyTag: null,
        },
        {
          id: "553489642",
          appId: 730,
          itemId: "553489642",
          itemName: "AWP | 毛细血管 (久经沙场)",
          marketHashName: "AWP | Capillary (Field-Tested)",
          shortName: "AWP | 毛细血管",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgQ2FwaWxsYXJ5IChGaWVsZC1UZXN0ZWQp.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_rare_weapon",
          rarityName: "军规级",
          rarityColor: "#4B69FF",
          exterior: "wearcategory2",
          exteriorName: "久经沙场",
          exteriorColor: "#f0ad4e",
          sellType: 1,
          quantity: 870,
          currencyId: 0,
          price: "0.16",
          subsidyPrice: "1",
          sellerPrice: "1",
          cnyPrice: "1",
          itemInfo: {
            quality: "",
            qualityName: "",
            qualityColor: "",
            rarity: "rarity_rare_weapon",
            rarityName: "军规级",
            rarityColor: "#4B69FF",
            type: "csgo_type_sniperrifle",
            typeName: "狙击步枪",
            slot: "",
            slotName: "",
            hero: "",
            heroName: "",
            heroAvatar: "",
            exterior: "wearcategory2",
            exteriorName: "久经沙场",
            exteriorColor: "#f0ad4e",
            weapon: "weapon_awp",
            weaponName: "AWP",
            itemSet: "set_community_25",
            itemSetName: "棱彩2号收藏品",
            stickerCapsule: "",
            stickerCapsuleName: "",
            patchCapsule: "",
            patchCapsuleName: "",
            customPlayer: "",
            customPlayerName: "",
            category: "",
            categoryName: "",
            item: "",
            itemName: null,
          },
          recommendTagInfo: [
            {
              id: null,
              recommendTag: "热度过万",
              stairName: null,
              secondLevelName: null,
              detailTag: "热度过万",
              tagColour: "#ff0000",
              detailUrl: "c5game://main.app/popularSkin",
              recommendWeight: null,
              createTime: null,
              updateTime: null,
              attrKey: null,
              valueKey: null,
              status: null,
              recommendType: null,
              attrValueId: null,
              urlType: 1,
            },
          ],
          subsidyTag: null,
        },
        {
          id: "22318",
          appId: 730,
          itemId: "22318",
          itemName: "AWP | 响尾蛇 (久经沙场)",
          marketHashName: "AWP | Pit Viper (Field-Tested)",
          shortName: "AWP | 响尾蛇",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGl0IFZpcGVyIChGaWVsZC1UZXN0ZWQp.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_mythical_weapon",
          rarityName: "受限",
          rarityColor: "#8847FF",
          exterior: "wearcategory2",
          exteriorName: "久经沙场",
          exteriorColor: "#f0ad4e",
          sellType: 1,
          quantity: 971,
          currencyId: 0,
          price: "0.23",
          subsidyPrice: "1.42",
          sellerPrice: "1.42",
          cnyPrice: "1.42",
          itemInfo: {
            quality: "",
            qualityName: "",
            qualityColor: "",
            rarity: "rarity_mythical_weapon",
            rarityName: "受限",
            rarityColor: "#8847FF",
            type: "csgo_type_sniperrifle",
            typeName: "狙击步枪",
            slot: "",
            slotName: "",
            hero: "",
            heroName: "",
            heroAvatar: "",
            exterior: "wearcategory2",
            exteriorName: "久经沙场",
            exteriorColor: "#f0ad4e",
            weapon: "weapon_awp",
            weaponName: "AWP",
            itemSet: "set_italy",
            itemSetName: "意大利小镇收藏品",
            stickerCapsule: "",
            stickerCapsuleName: "",
            patchCapsule: "",
            patchCapsuleName: "",
            customPlayer: "",
            customPlayerName: "",
            category: "",
            categoryName: "",
            item: "",
            itemName: null,
          },
          recommendTagInfo: [
            {
              id: null,
              recommendTag: "热度过万",
              stairName: null,
              secondLevelName: null,
              detailTag: "热度过万",
              tagColour: "#ff0000",
              detailUrl: "c5game://main.app/popularSkin",
              recommendWeight: null,
              createTime: null,
              updateTime: null,
              attrKey: null,
              valueKey: null,
              status: null,
              recommendType: null,
              attrValueId: null,
              urlType: 1,
            },
          ],
          subsidyTag: null,
        },
        {
          id: "553489767",
          appId: 730,
          itemId: "553489767",
          itemName: "AWP | 毛细血管 (战痕累累)",
          marketHashName: "AWP | Capillary (Battle-Scarred)",
          shortName: "AWP | 毛细血管",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgQ2FwaWxsYXJ5IChCYXR0bGUtU2NhcnJlZCk=.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_rare_weapon",
          rarityName: "军规级",
          rarityColor: "#4B69FF",
          exterior: "wearcategory4",
          exteriorName: "战痕累累",
          exteriorColor: "#B65C59",
          sellType: 1,
          quantity: 70,
          currencyId: 0,
          price: "0.35",
          subsidyPrice: "2.16",
          sellerPrice: "2.16",
          cnyPrice: "2.16",
          itemInfo: {
            quality: "",
            qualityName: "",
            qualityColor: "",
            rarity: "rarity_rare_weapon",
            rarityName: "军规级",
            rarityColor: "#4B69FF",
            type: "csgo_type_sniperrifle",
            typeName: "狙击步枪",
            slot: "",
            slotName: "",
            hero: "",
            heroName: "",
            heroAvatar: "",
            exterior: "wearcategory4",
            exteriorName: "战痕累累",
            exteriorColor: "#B65C59",
            weapon: "weapon_awp",
            weaponName: "AWP",
            itemSet: "set_community_25",
            itemSetName: "棱彩2号收藏品",
            stickerCapsule: "",
            stickerCapsuleName: "",
            patchCapsule: "",
            patchCapsuleName: "",
            customPlayer: "",
            customPlayerName: "",
            category: "",
            categoryName: "",
            item: "",
            itemName: null,
          },
          recommendTagInfo: [
            {
              id: null,
              recommendTag: "热度过万",
              stairName: null,
              secondLevelName: null,
              detailTag: "热度过万",
              tagColour: "#ff0000",
              detailUrl: "c5game://main.app/popularSkin",
              recommendWeight: null,
              createTime: null,
              updateTime: null,
              attrKey: null,
              valueKey: null,
              status: null,
              recommendType: null,
              attrValueId: null,
              urlType: 1,
            },
          ],
          subsidyTag: null,
        },
        {
          id: "553489579",
          appId: 730,
          itemId: "553489579",
          itemName: "AWP | 毛细血管 (略有磨损)",
          marketHashName: "AWP | Capillary (Minimal Wear)",
          shortName: "AWP | 毛细血管",
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgQ2FwaWxsYXJ5IChNaW5pbWFsIFdlYXIp.png",
          type: "csgo_type_sniperrifle",
          typeName: "狙击步枪",
          quality: "",
          qualityName: "",
          qualityColor: "",
          rarity: "rarity_rare_weapon",
          rarityName: "军规级",
          rarityColor: "#4B69FF",
          exterior: "wearcategory1",
          exteriorName: "略有磨损",
          exteriorColor: "#5cb85c",
          sellType: 1,
          quantity: 379,
          currencyId: 0,
          price: "0.39",
          subsidyPrice: "2.46",
          sellerPrice: "2.46",
          cnyPrice: "2.46",
          itemInfo: {
            quality: "",
            qualityName: "",
            qualityColor: "",
            rarity: "rarity_rare_weapon",
            rarityName: "军规级",
            rarityColor: "#4B69FF",
            type: "csgo_type_sniperrifle",
            typeName: "狙击步枪",
            slot: "",
            slotName: "",
            hero: "",
            heroName: "",
            heroAvatar: "",
            exterior: "wearcategory1",
            exteriorName: "略有磨损",
            exteriorColor: "#5cb85c",
            weapon: "weapon_awp",
            weaponName: "AWP",
            itemSet: "set_community_25",
            itemSetName: "棱彩2号收藏品",
            stickerCapsule: "",
            stickerCapsuleName: "",
            patchCapsule: "",
            patchCapsuleName: "",
            customPlayer: "",
            customPlayerName: "",
            category: "",
            categoryName: "",
            item: "",
            itemName: null,
          },
          recommendTagInfo: [
            {
              id: null,
              recommendTag: "热度过万",
              stairName: null,
              secondLevelName: null,
              detailTag: "热度过万",
              tagColour: "#ff0000",
              detailUrl: "c5game://main.app/popularSkin",
              recommendWeight: null,
              createTime: null,
              updateTime: null,
              attrKey: null,
              valueKey: null,
              status: null,
              recommendType: null,
              attrValueId: null,
              urlType: 1,
            },
          ],
          subsidyTag: null,
        },
      ];
      this.$forceUpdate();
    },
    getuserfenjie() {
      userdecompseList(this.userdecompseListPage).then((res) => {
        console.log("userdecompseList", res.data.rows);
        this.pickingList.push(...res.data.rows);
        if (res.data.rows.length < this.userdecompseListPage.size) {
          this.revenueListIsOver = "暂无更多";
        }
      }).catch((err)=>{
        console.log('catch',err);
      })
    },
    handlecategory(res) {
      this.category = res;
      this.detiallist = [];
      this.data.data.list = [];
      let key = null;
      switch (res) {
        case 1:
          key = 10;
          break;
        case 2:
          this.deliveryRecordsList = [];
          this.getuserfenjie();
          break;
        case 3:
          key = 12;
          break;
        case 4:
          key = 1;
          break;

        case 6:
          key = 2;
          break;

        default:
          break;
      }
      console.log(key);
      if (key != null) {
        this.deliveryRecordsIsOver = "加载中";
        this.deliveryRecordsList = [];
        this.deliveryRecordsPage.statusList.splice(0, 1, key);
        this.getlisttihuo();
      }
      // if (res == 4) {
      //   res = 0;
      // } else if (res == 1) {
      //   res = 10;
      // } else if (res == 5) {
      //   res = 1;
      // } else if (res == 6) {
      //   res == 3;
      // } else if (res == 2) {
      //   this.getuserfenjie();
      // }
      // // res = 0;
      // if (res != 2) {
      //   getExtractBillAPI(res).then((res) => {
      //     this.pickingList = res.data.rows;
      //   });
      // }
    },
    handletype(res) {
      this.type = res;
      const width = this.$refs.slider.offsetWidth;
      this.left = width * (res - 1);

      if (res == 2) {
        this.RevenueListPage.page=1
        this.RevenueList=[]
        this.getlisr();
      } else if (res == 3) {
        this.rechargeRecordsPage.page=1
        this.Recharge=[]
        this.getrechangeList();
      } else if (res == 4) {
        this.pickingList=[]
        this.deliveryRecordsList = [];
        this.pickingList=[]
        this.userdecompseListPage.page=1
        this.getuserfenjie();
      }
    },
    getrechangeList() {
      // orderList().then((res) => {
      //   this.Recharge = res.data.rows;
      // });
      rechargeRecords(this.rechargeRecordsPage).then((res) => {
        console.log("rechargeRecords", res);
        this.Recharge.push(...res.data.data.records)
        if(res.data.data.records.length<this.rechargeRecordsPage.size){
          this.rechargeRecordsIsover='暂无更多'
        }
      });
    },
    getlisr() {
      getUserLogList(this.RevenueListPage).then((res) => {
        console.log("getUserLogList", res.data.data);
        this.RevenueList.push(...res.data.data);
        if (res.data.data.length < this.RevenueListPage.size) {
          this.revenueListIsOver = "暂无更多";
        }
      });
    },
    getlisttihuo() {
      deliveryRecords(this.deliveryRecordsPage).then((res) => {
        console.log("deliveryRecords", res);
        this.deliveryRecordsList.push(...res.data.data);
        this.pickingList = [];
        if (this.deliveryRecordsPage.size > res.data.data.length) {
          console.log("deliveryRecordsIsOver", 1);
          this.deliveryRecordsIsOver = "暂无更多";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.recharge_box{
  height: 453px;
  overflow-y: auto;
  @media (max-width: 550px) {
    height: 390px;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
}
.fnjie_box {
  overflow-y: auto;
  height: 550px;
  // border: 2px solid red;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    height: 535px;
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
}
.deliveryRecords_box {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  height: 543px;
  overflow-y: auto;
  @media (max-width: 550px) {
    height: 393px;
  }
}
.revenueList {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  height: 629px;
  overflow-y: auto;
  @media (max-width: 550px) {
    height: 619px;
  }
}
.type {
  width: 203px;
  height: 42px;
  margin: 0 auto;
  margin-top: 14px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  @media (max-width: 550px) {
    height: 30px;
    width: 145px;
  }
  .type_btn {
    width: 49%;
    height: 35px;
    cursor: pointer;

    opacity: 1;
    transition: all 0.3s linear;
    color: rgba(114, 116, 119, 1);

    @media (max-width: 550px) {
      height: 25px;
      font-size: 10px;
    }
  }
  .active {
    background: linear-gradient(-270deg, #25f484 0%, #3a97e4 100%);
    border-radius: 70px 70px 70px 70px;
    color: rgba(255, 255, 255, 1);
  }
}
.home {
  width: 95%;
  margin: 0 auto;
}
.header_top {
  width: 100%;
  height: 56px;
  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
    opacity: 0;
  }
}
.tab {
  margin: 0 auto;
  margin-top: 14px;
  width: 301px;
  height: 42px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  position: relative;
  font-size: 14px;
  transition: all 0.2s linear;
  .btn {
    width: 33%;
    height: 100%;
  }
  .active {
    color: #fff;
  }

  color: #727477;
  @media (max-width: 525px) {
    width: 215px;
    height: 30px;
    font-size: 10px;
  }
  .ic {
    position: relative;
    z-index: 2;
  }
  .slider {
    position: absolute;
    width: 25%;
    height: 35px;
    background: linear-gradient(270deg, #25f484 0%, #3a97e4 100%);
    border-radius: 70px;
    // left: 0;
    z-index: 1;
    transition: left 0.2s linear;
    @media (max-width: 525px) {
      height: 25px;
    }
  }
}
.main {
  width: 100%;
  margin: 0 auto;
  margin-top: 14px;
}
.main_i_top {
  width: 100%;
  height: 112px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  padding: 14px 32px;

  @media (max-width: 550px) {
    padding: 10px 23px;

    height: 80px;
  }
  .btn {
    width: 100%;

    color: #727477;
    height: 35px;
    border-radius: 35px 35px 35px 35px;
    opacity: 1;
    border: 1px solid #727477;
    @media (max-width: 550px) {
      height: 25px;

      font-size: 13px;
    }
  }
  .active {
    width: 100%;
    height: 35px;
    border-radius: 35px 35px 35px 35px;
    color: #3a97e4;
    opacity: 1;
    border: 1px solid #3a97e4;
    @media (max-width: 550px) {
      height: 25px;
    }
  }
}
.main_i_weapon {
  width: 100%;
  height: 175px;
  background-color: #1f2023;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  background-size: 100% 100%;
  justify-content: space-around;
  @media (max-width: 550px) {
    height: 125px;

    font-size: 9px;
  }
}
.mian_i_img {
  width: 112px;
  // height: 112px;
  @media (max-width: 550px) {
    width: 80px;
  }
}
.main_ii_top {
  width: 100%;
  height: 70px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;

  color: #727477;

  @media (max-width: 550px) {
    height: 50px;

    font-size: 13px;
  }
}
.main_ii_center {
  width: 100%;
  height: 49px;
  background: #101115;
  border-radius: 7px 7px 7px 7px;
  opacity: 1;
  margin-top: 7px;

  color: #ffffff;
  @media (max-width: 550px) {
    height: 50px;

    font-size: 9px;
  }
}
.je,
.yt,
.zt {
  width: 97px;
}
.sj {
  width: 160px;
}
.main_iii_top {
  width: 100%;
  height: 70px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;

  color: #727477;
  opacity: 1;
  @media (max-width: 550px) {
    font-size: 13px;
    height: 50px;
  }
}
.main_iii_center {
  width: 100%;
  height: 49px;
  background: #101115;
  border-radius: 7px 7px 7px 7px;
  opacity: 1;
  font-size: 13px;
  margin-top: 7px;
  @media (max-width: 550px) {
    margin-top: 5px;

    font-size: 9px;

    height: 35px;
  }
}
.zfje,
.zfqd {
  width: 91px;
}
.zfdh,
.zfsj {
  width: 150px;
}
</style>
